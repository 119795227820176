import React from 'react';
import ReactDOM from 'react-dom/client';
import HomePage from './HomePage';
import NinjaWarriorTortenete from './subsites/ninja-tortenete';
import './style.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Parkour from './subsites/parkour';
import Gallery from './subsites/gallery';
import StreetWokout from './subsites/street-workout';
import OCR from './subsites/ocr';
import Calendar from './subsites/calendar';
import NotFound from './components/NotFound';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-MN6F0H0K3X');

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
          <Route index element={<HomePage />} />
          <Route path="*" element={<NotFound />} />
          <Route path='/ninja-tortenet' element={<NinjaWarriorTortenete />} />
          <Route path="/parkour" element={<Parkour />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ocr" element={<OCR />} />
          <Route path="/street-workout" element={<StreetWokout />} />
          <Route path="/naptar" element={<Calendar />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
