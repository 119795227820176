import React, { useState, useEffect } from 'react';
import starImg from '../images/homepage/star.png';

const Star = () => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  useEffect(() => {
    // Generate random positions for the star
    const newTop = Math.random() * 90;
    const newLeft = Math.random() * 100;
    setTop(newTop);
    setLeft(newLeft);
  }, []); // Run once on component mount to set initial position

  return (
    <div className="absolute -z-10" style={{
        top: `${top}%`,
        left: `${left}%`,
        transform: `rotate(${Math.random() * 25}deg)`,
      }}>
      <img src={starImg} alt="Star" />
    </div>
  );
}

export default Star;
