import React, { useState } from "react";
import GallerySection from "../components/GallerySection";
import { Link } from "react-router-dom";

function Gallery() {
  const categories = [
    {
      id: "kezdetek",
      title: "Kezdetek",
      folderName: "kezdetek",
      numberOfImages: 6,
      extension: "jpg",
    },
    {
      id: "artistanap",
      title: "ARTISTA nap a Ninjapadláson",
      folderName: "artistanap/",
      numberOfImages: 10,
      extension: "jpeg",
    },
    {
      id: "ocr23",
      title: "OCR Európa Bajnokság 2023",
      folderName: "versenyek/ocr-eb",
      numberOfImages: 7,
      extension: "jpeg",
    },
    {
      id: "spartanKids23",
      title: "Spartan Kids 2023 (Komló)",
      folderName: "versenyek/SpartanKids23",
      numberOfImages: 6,
      extension: "jpg",
    },
    {
      id: "vulcanrun",
      title: "Vulcan Run",
      folderName: "versenyek/vulcanrun",
      numberOfImages: 6,
      extension: "jpg",
    },
    {
      id: "gravity",
      title: "Gravity Boulder",
      folderName: "versenyek/GravityBoulder",
      numberOfImages: 7,
      extension: "jpg",
    },
    {
      id: "maty",
      title: "Szeged Maty-ér OCR",
      folderName: "versenyek/Maty",
      numberOfImages: 10,
      extension: "jpg",
    },
    {
      id: "taltosfutas",
      title: "Táltos futás",
      folderName: "versenyek/Taltos-futas",
      numberOfImages: 4,
      extension: "jpg",
    },
    {
      id: "ninjabarlang",
      title: "Ninjabarlang",
      folderName: "versenyek/Ninjabarlang",
      numberOfImages: 6,
      extension: "jpg",
    },
    {
      id: "spartan",
      title: "Spartan",
      folderName: "versenyek/Spartan",
      numberOfImages: 4,
      extension: "jpg",
    },
    {
      id: "tuskes",
      title: "Tüskésréti gyakorló edzés",
      folderName: "Csapatepito-programok/Tuskesret-gyakorlo-edzes",
      numberOfImages: 22,
      extension: "jpeg",
    },
    {
      id: "platan",
      title: "PlatánON",
      folderName: "Csapatepito-programok/PlatanON",
      numberOfImages: 8,
      extension: "jpg",
    },
    {
      id: "team",
      title: "A versenycsapat",
      folderName: "HOPExtrem-team-a-verseny-csapat",
      numberOfImages: 7,
      extension: "jpg",
    },
    {
      id: "cave",
      title: "Barlangászat",
      folderName: "Csapatepito-programok/barlangaszat",
      numberOfImages: 13,
      extension: "jpg",
    },
    {
      id: "tabor",
      title: "Táborok",
      folderName: "tabor/",
      numberOfImages: 14,
      extension: "jpg",
    },
    {
      id: "gravity2",
      title: "II Gravity Ninja Verseny",
      folderName: "versenyek/II-Gravity/",
      numberOfImages: 12,
      extension: "jpg",
    },
  ];

  const [activeCategory, setActiveCategory] = useState(categories[0].id);

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
  };

  return (
    <div>
      <div className="w-auto lg:h-14 h-auto flex-row flex justify-around bg-[#256555]">
        <div className="w-screen hidden lg:mr-8 lg:flex pl-4">
          <a href="">
            <h1 className="w-auto h-14 flex lg:text-center items-center text-slate-100 font-bold text-3xl font-bebas">
              <Link to="/">Ninja Padlás SE - Galéria</Link>
            </h1>
          </a>
        </div>
        <div className="w-auto lg:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
          <div className="flex lg:flex-row flex-col">
            <a href="/" className="ml-10">
              {" "}
              <h1>
                <Link to="/">Vissza</Link>
              </h1>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full h-auto flex flex-col items-center p-5">
        <ol className="w-full h-auto text-center items-center grid grid-auto-flow lg:grid-cols-4 grid-cols-2 lg:grid-rows-4 grid-rows-2 gap-3">
          {categories.map((category) => (
            <a
              key={category.id}
              href="#"
              onClick={() => handleCategoryClick(category.id)}
              className="text-black font-bold cursor-pointer"
            >
              {category.title}
            </a>
          ))}
        </ol>
      </div>

      <div>
        {activeCategory &&
          categories.map((category) => {
            if (category.id === activeCategory) {
              return (
                <GallerySection
                  key={category.id}
                  title={category.title}
                  folderName={category.folderName}
                  numberOfImages={category.numberOfImages}
                  extension={category.extension}
                />
              );
            }
            return null;
          })}
      </div>

      <footer
        id="footer"
        className="w-screen h-auto p-5 bg-[#256555] text-slate-100 flex flex-col text-center items-center justify-evenly"
      >
        <h3 className="text-[1.2rem] font-normal text-center p-5">
          A képek jogi védelem alatt állnak. Felhasználásukhoz a következő email
          címen lehet engedélyt igényelni:
        </h3>

        <a
          href="mailto:info@ninjapadlas.hu"
          className="text-[1.1rem] text-white underline"
        >
          info@ninjapadlas.hu
        </a>

        <h1 className="text-[1.8rem] font-bold text-center p-5">
          © 2024 Ninja Padlás SE
        </h1>
      </footer>
    </div>
  );
}

export default Gallery;
