import React, { Component } from "react";
import { Link } from "react-router-dom";

class StreetWorkout extends Component {
  scrollUp = () => {
    window.scrollTo(0, 0);
  };
  render() {
    this.scrollUp();
    return (
      <div>
        <div className="w-auto md:h-14 h-auto flex-row flex md:justify-around justify-start items-center p-3 bg-[#256555]">
          <div className="w-screen hidden md:mr-8 md:flex pl-4">
            <h1 className="w-auto h-14 flex md:text-center items-center text-slate-100 font-bold text-3xl font-bebas indent-8">
              Street Workout
            </h1>
          </div>
          <div className="w-auto md:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
            <div className="flex md:flex-row flex-col">
              <h1 className="cursor-pointer">
                <Link to="/">Vissza</Link>
              </h1>
            </div>
          </div>
        </div>
        <div className="w-screen h-auto md:pr-16 md:pl-16 md:pt-16 p-[20px] pt-12">
          <div>
            <h1 className="text-black text-[2.5rem] font-bold font-bebas">
              Tudnivalók a Street Workout-ról
            </h1>
            <div className="md:w-[350px] w-[250px] h-2 bg-black rounded"></div>
          </div>
          <div className="h-auto mt-16">
            <p className="font-light text-xl text-black">
              A Street Workout vagy más néven „calisthenics” (ezt a kifejezést
              főként külföldön használják) egy saját testsúly központú
              mozgásforma. A 2000-es évektől kezdve drasztikusan emelkedik a
              sportág népszerűsége az egyszerűsége és elérhetősége miatt. Mára
              már Magyarországon is számos helyen elérhetőek a kültéri street
              workout parkok, amiket bárki használhat.
              <br />
              <br />A sportág lényege, hogy saját testsúly használatával
              hajtsunk végre különböző nehézségű gyakorlatokat, az egyszerű
              guggolástól kezdve a kézenálláson keresztül egészen a
              támaszmérlegig. A gyakorlatokat alapvetően két csoportba
              oszthatjuk. Vannak az erő elemek melyek kontrollált mozgások, és
              vannak a dinamikus elemek, melyek inkább akrobatikus mozgások.
              <br />
              <br />
              Bátran elkezdhetik a teljesen kezdők is, hiszen akár egy
              edzőparkban, vagy otthon is begyakorolhatják az elemeket. Mint
              minden más sportágban, itt is nagyon fontos egy általános és
              sportágspecifikus bemelegítés (pl.: lapockamozgások), valamint a
              sportoló szintjének megfelelő gyakorlatok kiválasztása is, mert
              ezekkel elkerülhetők a súlyosabb sérülések.
              <br />
              <br />
              Kezdőként az alapok elsajátítása a legfontosabb: a húzódzkodás,
              tolódzkodás, fekvőtámasz, guggolás és a törzs izmainak
              megerősítése. Megfelelő alapok nélkül a haladó szintű gyakorlatok
              szinte elérhetetlenek lesznek.
              <br />
              <br />A sportág egyik jeles hazai képviselője Szarka Ákos a Tight
              Hold alapítója, személyi edző, az év funkcionális edzője 2022-ben.
              Elsőként ő szervezte meg és azóta is oktatja a street workout
              tanfolyamokat. Világbajnoki bronzérmes, többszörös világkupa
              dobogós street workout versenyző, 2022 óta világszövetségi
              koordinátor.
            </p>
          </div>
          <br />
          <h3 className="w-auto text-[#40806e] text-right text-[1.25rem] font-bold">
            Horgas Péter
          </h3>
        </div>
      </div>
    );
  }
}

export default StreetWorkout;
