import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HomePage, hideMobileMenu } from "../HomePage";
import banner from "../images/homepage/banner.jpg";
import banner2 from "../images/homepage/banner.png";


class Slideshow extends Component {
  state = {
    images: [banner, banner2],
    currentImageIndex: 0,
  };

  componentDidMount() {
    this.preloadImages();
    this.slideshowInterval = setInterval(this.nextImage, 3500);
    this.handleClick();
  }

  componentWillUnmount() {
    clearInterval(this.slideshowInterval);
  }

  preloadImages = () => {
    this.state.images.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  };

  nextImage = () => {
    this.setState((prevState) => ({
      currentImageIndex:
        (prevState.currentImageIndex + 1) % this.state.images.length,
    }));
  };

  handleClick = () => {
    this.props.callFunction();
  };

  render() {
    const { images, currentImageIndex } = this.state;
    const imageUrl = images[currentImageIndex];
    return (
      <div
        id="hero-section"
        onClick={this.handleClick}
        style={{
          backgroundImage: `url(${imageUrl})`,
          transition: "all 1.5s ease-in-out",
          color: "#fff",
        }}
        className="w-auto lg:h-[85vh] h-[100%] lg:p-24 flex text-center justify-center items-center bg-cover bg-center flex-col lg:rounded-b-3xl rounded-b-2xl"
      >
        <div
          id="mobile-menu"
          style={{
            transition: "all 0.5s ease",
            backgroundColor: "#004e41",
            color: "#fff",
          }}
          className="w-[100%] h-auto absolute top-14 p-4 rounded-bl-lg"
        >
          <div className="flex flex-col justify-evenly text-left">
            <a href="#aboutus" className="text-left font-normal text-[1.5rem]">
              <h1 className="">Rólunk</h1>
            </a>
            <a
              href="#sportagak"
              className="text-left font-normal text-[1.5rem]"
            >
              <h1 className="">Sportágak</h1>
            </a>
            <p className="text-left font-normal text-[1.5rem]">
              <h1 className="">
                <Link to="/gallery">Galéria</Link>
              </h1>
            </p>
            <p className="text-left font-normal text-[1.5rem]">
              <h1 className="">
                <Link to="/naptar">Naptár</Link>
              </h1>
            </p>
            <a href="#footer" className="text-left font-normal text-[1.5rem]">
              <h1 className="">Kapcsolat</h1>
            </a>
          </div>
        </div>
        <header
          className="w-[100vw] h-[75vh] flex justify-center text-center items-center"
          onClick={this.hideMobileMenu}
        >
          <h1
            id="hero-text"
            className="max-w-[1000px] h-auto text-white font-bold sm:text-[4rem] md:text-[6rem] text-[3rem] lg:pr-10"
          >
            Ninja Padlás SE
          </h1>
        </header>
      </div>
    );
  }
}

export default Slideshow;
