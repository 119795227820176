import React, { Component } from "react";
import { Link } from "react-router-dom";

class Parkour extends Component {
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    this.scrollUp();
    return (
      <div>
        <div className="w-auto md:h-14 h-auto flex-row flex md:justify-around justify-start items-center p-3 bg-[#256555]">
          <div className="w-screen hidden md:mr-8 md:flex pl-4">
            <h1 className="w-auto h-14 flex md:text-center items-center text-slate-100 font-bold text-3xl font-bebas indent-8">
              Parkour
            </h1>
          </div>
          <div className="w-auto md:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
            <div className="flex md:flex-row flex-col">
              <h1 className="cursor-pointer">
                <Link to="/">Vissza</Link>
              </h1>
            </div>
          </div>
        </div>
        <div className="w-screen h-auto md:pr-16 md:pl-16 md:pt-16 p-[20px] pt-12">
          <div>
            <h1 className="text-black text-[2.5rem] font-bold font-bebas">
              Parkour, free running
            </h1>
            <div className="md:w-[350px] w-[250px] h-2 bg-black rounded"></div>
          </div>
          <div className="h-auto mt-16">
            <p className="font-light text-xl text-black">
              A Free Running, parkour rövid története
              <br />
              <br />
              A free running, vagy parkour, mint sportág az akrobatikus ügyesség
              és az atletikus képességek különleges, szabadstílusú ötvözete. A
              szabadfutás célja látszólag bármilyen természetes vagy mesterséges
              akadály leküzdése a mozgás szépségének és dinamikájának
              hangsúlyozásával. Az igazi cél viszont a futó saját képességeinek
              alapos és pontos megismerése, illetve annak fejlesztése.
              <br />
              <br />
              <br />
              A magyarul szabadfutásként emlegetett sport szülőatyjainak
              Sébastien Foucant és David Belle-t tekintik, akik együtt találták
              ki ezt a merész mutatványokkal tarkított városi akadályfutást.
              <br />
              <br />
              A parkour lényege, hogy a sportoló keresztüljut - akadályokon,
              padokon, falakon, tetőkön keresztül. Belle katona édesapjával
              vidéken élt, ahol az erődben naphosszat fejlesztette atlétikai
              képességeit a fák közt futva, ugrálva, illetve azokra felmászva.
              Mikor apja úgy döntött, hogy Párizs külvárosába költöznek,
              Belle-nek muszáj volt addigi mozgását a városi környezethez
              igazítani. Néhány évvel később találkozott Sébastien Foucannal,
              akivel végleges fazont szabtak a parkournek. Belle, a parkour
              lényegét abban látja, hogy a sportoló miközben minél gyorsabban
              jut ’A’ pontból a ’B’ pontba, az útjába kerülő akadályokon
              ugrásokat felhasználva akrobatikus mozdulatokat mutat be. A
              mozdulatokban lennie kell sebességnek és gördülékenységnek.
              <br />
              <br />A free running a sport Foucan által továbbfejlesztett, és az
              ázsiai filozófiával összeolvasztott elképzelését takarja, amely
              szerint a szabadfutás a mozgás esztétikumának, a szabadság
              kifejezésének és a pozitív energiák áramlásának a megtestesítője.
              Sébastien szerint "senki sem talál fel semmit, csupán néha ihletet
              kapunk és élünk az alkalommal". Hiszi, hogy"filozófia nélkül a
              cselekvésnek nincsen jelentése".És filozófiájához tartozik az is,
              hogy az élet akadályokból és kihívásokból áll, melyeket le kell
              küzdeni ahhoz, hogy előrelépjünk."Ha jártassá válsz a
              szabadfutásban, az életed hátra lévő részére nyersz valamit. A
              szabadfutás filozófiájának megértése pedig sokkal távolabb mutat
              az egyszerű mozgásnál vagy a mozgásban elért teljesítménynél. Még
              akár azt is lehet mondani, hogy egy mindennapra szóló lecke az
              életről!" - jelenti ki a világ egyik legjobb szabadfutója.
            </p>
          </div>
          <br />
          <h3 className="w-auto text-[#40806e] text-right text-[1.25rem] font-bold">
            Horgas Péter
          </h3>
        </div>
      </div>
    );
  }
}

export default Parkour;
