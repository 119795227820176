import React, { Component } from "react";
import { Link } from "react-router-dom";

let width = window.innerWidth;
let height = window.innerHeight;

class Calendar extends Component {
  componentDidMount() {
    width = window.innerWidth;
    height = window.innerHeight;
  }

  componentDidUpdate() {
    width = window.innerWidth;
    height = window.innerHeight;
  }


  render() {
    return (
      <div>
        <div className="w-auto lg:h-14 h-auto flex-row flex justify-around bg-[#256555]">
        <div className="w-screen hidden lg:mr-8 lg:flex pl-4">
          <a href="">
            <h1 className="w-auto h-14 flex lg:text-center items-center text-slate-100 font-bold text-3xl font-bebas">
              <Link to="/">Ninja Padlás SE - Események</Link>
            </h1>
          </a>
        </div>
        <div className="w-auto lg:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
          <div className="flex lg:flex-row flex-col">
            <a href="/" className="ml-10">
              {" "}
              <h1>
                <Link to="/">Vissza</Link>
              </h1>
            </a>
          </div>
        </div>
      </div>
        <center>
          <h1 className="font-bold text-black text-[2rem] text-center mt-12">
            Eseménynaptár
          </h1>
          <div className="lg:w-[250px] w-[200px] h-1.5 bg-black rounded-2xl mb-12"></div>
          <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Europe%2FBudapest&title=Esem%C3%A9nynapt%C3%A1r&showNav=1&showTitle=0&showDate=1&showTabs=0&showCalendars=&src=MWEyNGQ4MmJjYWZhMTY3YmYxN2U0ODQ0NzQwZGM3NDBkMzJmNzcwZjc4YWJjNGUwYzU2OTc1NGZlNGQwM2U0OEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%23B39DDB"
            width={width < 600 ? width : width / 1.5}
            height={height < 600 ? height : height / 1.5}
            frameBorder="0"
            title="Eseménynaptár"
            id="iframeElement"
          ></iframe>
          <br />
          <div className="w-100 flex flex-col md:flex-row justify-evenly text-center text-[1.5rem]">
            <b style={{ fontWeight: 700 }}>🏃‍♂️ - OCR versenyek</b>
            <b style={{ fontWeight: 700 }}>🥷 - Ninja versenyek</b>
            <b style={{ fontWeight: 700 }}>👨‍👩‍👧‍👦 - Családi versenyek</b>
            <b style={{ fontWeight: 700 }}>*️⃣ - Egyéb események</b>
          </div>
        </center>
        <div
          id="description"
          onClick={this.hideMobileMenu}
          className="w-screen h-full relative lg:pr-16 lg:pl-16 lg:pt-16 p-[20px] py-12"
        >
          <div>
            <div className="flex justify-between items-center">
              <h1 className="text-black text-[2.5rem] font-bold font-bebas">
                Események, versenynaptár
              </h1>
            </div>
            <div className="sm:w-[550px] w-[260px] h-2 bg-black rounded"></div>
          </div>
          <div className="h-auto mt-16">
            <p className="font-light text-xl text-black">
              Ezen az oldalon található az összes esemény, ami a Ninja Padlással
              kacsolatos. Azok a versenyek láthatók itt, emelyekre a Ninja
              Padlásról érkező versenyzők beneveztek, illetve azok a versenyek
              is, amelyeket a Ninja Padlás szervez.
              <br />
              <br />
              <b style={{ fontWeight: 700 }}>
                A Ninja Padlás SE naptárából saját naptárba való másolás
                lehetősége fennáll. Az adott eseményre kattintva megjelenik egy
                ablak, ahol a <i>másolás saját naptárba</i> szövegre kattintva a
                rendszer átmásolja az eseményt.
              </b>
              <b style={{ fontWeight: 700 }}>A naptár nyomtatható is.</b>
              <br />
              <br />
              Egyéb események és hírek is megjelennek az oldalon.
              <br />
              <br />A naptár és a hírek teljesen nyilvánosak!
            </p>
            <br />
          </div>
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default Calendar;
