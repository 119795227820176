import React from 'react';

const GallerySection = ({ title, folderName, numberOfImages, extension }) => {
  const images = Array.from(Array(numberOfImages).keys()).map((i) => (
    <img
      key={i}
      src={`../images/${folderName}/${i+1}.${extension}`}
      className="w-96 h-fit m-5 rounded-2xl"
      alt="Galériában lévő kép"
    />
  ));
  return (
    <div id={title.toLowerCase().replace(/ /g, '_')} className="flex flex-col w-full h-full items-center justify-center">
      <h1 className="font-bold text-black text-[2rem] text-center">{title}</h1>
      <div className="lg:w-[250px] w-[200px] h-1.5 bg-black rounded-2xl"></div>
      <div className="w-full lg:inline-block lg:max-w-5xl lg:pb-10 lg:mb-10 lg:gap-5 lg:columns-3 p-5 flex flex-col justify-start items-center">
        {images}
      </div>
    </div>
  );
};

export default GallerySection;
