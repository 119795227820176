import React, { Component } from 'react';
import Star from './Star';
import { Link } from 'react-router-dom';

class NotFound extends Component {
    render() {
        return (
            <div className="w-100 h-[90vh] flex flex-col justify-center items-center text-center">
                <h1 className="lg:text-[14rem] text-[10rem] font-extrabold text-center">404</h1>
                <h2 className="lg:text-[4rem] text-[2rem] text-center font-bold">A keresett oldal nem található</h2>
                <Link to="/" className="text-[2rem] text-center font-bold underline">Vissza a főoldalra</Link> 
            </div>
        );
    }
}

export default NotFound;
