import React, { Component } from "react";
import vagotpusztaImg from "./images/homepage/vagotpuszta.png";
import ninjapdalasImg from "./images/homepage/ninjapadlas.png";
import hcLinear from "./images/sponsors/hc-linear.png";
import dentImpact from "./images/sponsors/dent-impact.png";
import starImg from "./images/homepage/star.png";
import TRock from "./images/sponsors/t-rock.png";
import { Link } from "react-router-dom";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import Hero from "./components/Hero";
import Comment from "./components/comment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faBars,
  faBook,
  faArrowRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import Star from "./components/Star";

class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      topPosition: Math.random() * 100,
      leftPosition: Math.random() * 100,
    };
  }


  showMobileMenu = () => {
    const mobileMenu = document.getElementById("mobile-menu");
    mobileMenu.classList.toggle("hidden");
    mobileMenu.classList.toggle("flex");
  };

  componentDidMount() {
    this.hideMobileMenu();
    this.introAnimation();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  introAnimation = () => {
    const text = document.querySelector("#hero-text");

    text.animate(
      [
        {
          opacity: 0,
          transform: "translateY(100%)",
        },
        {
          opacity: 1,
          transform: "translateY(0%)",
        },
      ],
      {
        duration: 450,
        fill: "forwards",
      }
    );
  };

  resize() {
    this.setState({ width: window.innerWidth <= 768 });
    if (window.innerWidth >= 768) {
      this.hideMobileMenu();
    }
  }

  hideMobileMenu = () => {
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.classList.add("hidden");
    mobileMenu.classList.remove("flex");
  };

  handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  render() {

    return (
      <div className="w-auto h-max font-sans flex flex-col justify-start scroll-smooth overflow-x-hidden">
        <div className="w-auto lg:h-14 h-auto flex-row flex items-center justify-between bg-[#40806e] text-white">
          <div className="w-screen lg:mr-8 mr-2 lg:flex pl-4">
            <h1 className="w-auto h-14 flex lg:text-center items-center font-bold text-3xl font-bebas">
              Ninja Padlás SE
            </h1>
          </div>
          <div className="w-auto h-auto mr-2 hidden lg:flex flex-row text-center items-center font-medium text-xl">
            <div className="flex lg:flex-row flex-col">
              <a href="#aboutus" className="ml-10">
                <h1 className="">Rólunk</h1>
              </a>

              <a href="#sportagak" className="ml-10">
                <h1 className="">Sportágak</h1>
              </a>

              <p className="ml-10">
                <h1 className="">
                  <Link to="/gallery">Galéria</Link>
                </h1>
              </p>

              <p className="ml-10">
                <h1 className="">
                  <Link to="/naptar">Naptár</Link>
                </h1>
              </p>

              <a href="#footer" className="ml-10 mr-10">
                <h1 className="">Kapcsolat</h1>
              </a>
            </div>
          </div>
          <FontAwesomeIcon
            icon={faBars}
            className="lg:hidden text-3xl relative right-4"
            onClick={this.showMobileMenu}
          />
        </div>
        <Hero callFunction={this.hideMobileMenu} />
        <br />
        <div
          id="aboutus"
          onClick={this.hideMobileMenu}
          className="w-screen h-full relative lg:pr-16 lg:pl-16 lg:pt-16 p-[20px] py-12"
        >
          <div>
            <div className="flex justify-between items-center">
              <h1 className=" text-[2.5rem] font-bold font-bebas">Rólunk</h1>
            </div>
            <div className="w-[150px] h-2 bg-[#111] rounded"></div>
          </div>
          <Star top={this.state.topPosition} left={this.state.leftPosition} />

          <div className="h-auto mt-16">
            <p className="font-light text-xl ">
              Sportegyesületünket 2022 decemberében alapítottuk azzal a céllal,
              hogy a környezetünkben extrémsportolást kedvelő fiatalokat (és
              szüleiket) összefogjuk, edzéslehetőséget, táborozásokat,
              versenyfelkészüléseket, bemutatókat, szabadidős programokat
              biztosítsunk számukra.
              <br />
              <br />A hazánkban egyre népszerűbb extrémsportok közül:
              <ol className="list-disc pl-12">
                <li>Ninja Warrior jellegű</li>
                <li>OCR (Obstacle Course Racing) – terepakadály futás</li>
                <li>Parkour</li>
                <li>Street Workout sportágak népszerűsítése.</li>
              </ol>
              <br />
              Az egyesület megalapításának története azonban régebbre nyúlik
              vissza.
              <br />
              2018-ban Horgas Péter (alapító) a pellérdi házuk padlásterében
              kialakított egy kis edzőtermet, ahol a környék gyermekei számára
              kívánt kötetlenebb formában sportolási lehetőséget biztosítani.
              Ezt az edzőtermet a gyerekek nevezték el Ninjapadlásnak.
              <br />
              A Ninjapadlás rövid idő alatt népszerű lett, a szülők is, a
              gyerekek is megkedvelték az edző mentalitását, a más sportágakban
              nem szokványos eszközökön és felszabadult formában végezhető
              mozgásfejlesztést. Külön érdem, hogy a szorongó gyerek
              felszabadulttá, a hiperaktív, figyelemzavarral (ADHD-val) küzdő,
              sőt Aspergeres gyermekek is otthonra lelnek. Ugyanakkor néhány
              gyermek – otthagyva akár a több éves versenysportolói múltját –
              teljes erőbedobással és motivációval állt rá a ninjázásra és a
              futásra, így elkezdtek ninja versenyekre és OCR versenyekre is
              benevezni. Az első versenyektől kezdve dobogós helyezéseket érnek
              el!
              <br />
              Így vált a kis vidéki edzőterem az országosan is elismert fiatal
              sportolók „otthonává”, s érett meg arra, hogy sportegyesületet is
              indítsunk. Ezáltal nyílt meg a lehetőség, hogy nemzetközi
              versenyekre kvalifikáltathassuk a versenyzőinket, valamint
              szélesebb körben adjunk lehetőséget a sajátos nevelési igényű
              gyermekeknek is a sportolás terén.
            </p>
            <br />
            <h1 className=" text-[2rem] font-bold font-bebas text-center my-5">
              Vezetőség
            </h1>
            <div className="w-full h-auto flex flex-col md:flex-row justify-evenly items-center text-center">
              <div className="flex flex-col p-5">
                <h4 className=" font-bold">Nagy Dóra</h4>
                <h2 className=" font-bolder">elnökhelyettes</h2>
                <h6 className=" font-normal">+36 30 643 7850</h6>
              </div>
              <div className="flex flex-col p-5">
                <h4 className=" font-bold">Horgas Péter</h4>
                <h2 className=" font-bolder">elnök</h2>
                <h6 className=" font-normal">+36 70 389 2121</h6>
              </div>
              <div className="flex flex-col p-5">
                <h4 className=" font-bold">Lendvai Andrea</h4>
                <h2 className=" font-bolder">elnökhelyettes</h2>
                <h6 className=" font-normal">+36 30 364 7713</h6>
              </div>
            </div>
          </div>
          <br />

          <br />
        </div>
        <div
          onClick={this.hideMobileMenu}
          className="w-screen h-auto flex flex-col text-center items-center justify-evenly relative"
          id="sportagak"
        >
          <div className="w-full flex flex-col text-center">
            <h1 className="font-bebas font-[800] text-[3.5rem]  pb-12">
              Sportágak
            </h1>

            <div className="w-full flex flex-col md:flex-row justify-evenly items-center">
              <div className="flex flex-col">
                <div className="flex flex-col items-center text-center p-5">
                  <div className="w-44 h-44 bg-[#40806e] rounded-full flex text-center items-center justify-center">
                    <h1 className="text-[5rem] font-bold text-white">N</h1>
                  </div>
                  <br />
                  <h1 className=" text-[2rem] font-bold">
                    <br />
                    Ninja Challange
                  </h1>
                  <br />
                  <p className="text-[#40806e] font-bold text-[1.2rem]">
                    <Link to="/ninja-tortenet" className="underline">
                      Tudjon meg többet
                    </Link>
                  </p>
                </div>
              </div>
              <Star top={this.state.topPosition} left={this.state.leftPosition} />

              <div className="flex flex-col">
                <div className="flex flex-col items-center text-center p-5">
                  <div className="w-44 h-44 bg-[#40806e] rounded-full flex text-center items-center justify-center">
                    <h1 className="text-[5rem] font-bold text-white">O</h1>
                  </div>
                  <br />
                  <h1 className=" text-[2rem] font-bold">
                    OCR <br /> terepakadály-futás
                  </h1>
                  <br />
                  <p className="text-[#40806e] font-bold text-[1.2rem]">
                    <Link to="/ocr" className="underline">
                      Tudjon meg többet
                    </Link>
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full flex flex-col md:flex-row justify-evenly items-center">
              <div className="flex flex-col">
                <div className="flex flex-col items-center text-center p-5">
                  <div className="w-44 h-44 bg-[#40806e] rounded-full flex text-center items-center justify-center">
                    <h1 className="text-[5rem] font-bold text-white">P</h1>
                  </div>
                  <br />
                  <h1 className=" text-[2rem] font-bold">
                    <br />
                    Parkour
                  </h1>
                  <br />
                  <p className="text-[#40806e] font-bold text-[1.2rem]">
                    <Link to="/ninja-tortenet" className="underline">
                      Tudjon meg többet
                    </Link>
                  </p>
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex flex-col items-center text-center p-5">
                  <div className="w-44 h-44 bg-[#40806e] rounded-full flex text-center items-center justify-center">
                    <h1 className="text-[5rem] font-bold text-white">S</h1>
                  </div>
                  <br />
                  <h1 className=" text-[2rem] font-bold">
                    <br /> Street Workout
                  </h1>
                  <br />
                  <p className="text-[#40806e] font-bold text-[1.2rem]">
                    <Link to="/street-workout" className="underline">
                      Tudjon meg többet
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <h1 className="font-bebas font-[800] text-[3.5rem]  py-12">
            Helyszínek
          </h1>
          <div
            className="w-screen h-auto flex md:flex-row flex-col items-center justify-evenly"
            id="helyszinek"
          >
            <div className="md:w-[45%] w-screen h-auto flex flex-col items-center justify-evenly lg:p-0 p-5">
              <h1 className="text-[3rem] font-bold  font-bebas">
                Ninja Padlás
              </h1>
              <br />
              <img
                className="w-[100%] relative h-[100%] rounded-2xl"
                src={ninjapdalasImg}
                alt="Ninjapadlás kép"
              />
            </div>
            <div className="md:w-[45%] w-screen h-auto flex flex-col items-center justify-evenly lg:p-0 p-5">
              <h1 className="text-[3rem] font-bold  font-bebas">Vágotpuszta</h1>
              <br />
              <img
                className="w-[100%] relative h-[100%] rounded-2xl"
                src={vagotpusztaImg}
                alt="Vágotpuszta kép"
              />
            </div>
          </div>
          <br />
          <br />
          <br />
          <Star top={this.state.topPosition} left={this.state.leftPosition} />
          <h1 className="font-bebas font-[800] text-[3.5rem]  py-12">
            Rólunk mondták
          </h1>

          <br />
          <div className="w-full h-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-left p-5 m-5">
            <Comment
              text="Felkészült edző, jól felépített edzések!"
              name="Fekete Tünde"
            />
            <Comment
              text="Jó hangulat, változatos edzések, egy nagyon jó edzővel."
              name="Tamás Szénás-Máthé"
            />
            <Comment
              text="Nagyon jó terem, kreatív akadályok és jó hangulat!"
              name="Dombi András"
            />
            <Comment
              text="Nagyon jó a terem, tele jobbnál-jobb akadályokkal! A jókedv garantált és Horgas Péter mindenkiből kihozza a maximumot, odafigyel mindenkire! Szeretünk ide járni, bárcsak előbb megtaláltuk volna!"
              name="Széligné Zóka Krisztina"
            />
            <Comment
              text="A kisfiam kedvenc helye. Él-hal az itteni edzésekért. Péter egy profi oktató. Olyan szinten varázsolja el a gyerekeket odafigyelésével és tudásával, hogy az szinte csoda. Bármikor nyugodt szívvel tudom otthagyni a kissrácot. (és ez fontos) Köszi Péter!"
              name="Maráth Péter"
            />
            <Comment
              text="Sokoldalúan képzett, lelkes, gyermekszerető edző, aki képes arra, hogy mindenkiből kihozza a maximumot."
              name="Katalin Bányi"
            />
          </div>
          <br />
          <br />
          
          <button
            onClick={this.handleGoToTop}
            className="fixed bottom-4 right-4 bg-[#40806e] text-white shadow-lg rounded-full hover:rounded-xl hover:bg-[#256555] p-5 focus:outline-none z-40"
          >
            <FontAwesomeIcon icon={faArrowUp} size="2x" />
          </button>
          
          <footer
            id="footer"
            className="w-screen h-auto p-5 bg-[#40806e] text-white flex flex-col text-center items-center justify-evenly"
          >
            <div className="w-[75%] flex sm:flex-row flex-col justify-between">
              <div className="flex flex-col text-left p-5">
                <h1 className="text-[1.4rem] font-bold">Kapcsolat</h1>
                <br />
                <p className="text-white">
                  Cím: Pellérd, Radnóti Miklós u. 12, 7831
                </p>
                <p className="text-white">
                  Email:{" "}
                  <a
                    href="mailto:info@ninjapadlas.hu"
                    className="text-white underline"
                  >
                    info@ninjapadlas.hu
                  </a>
                </p>
              </div>
              <div className="flex flex-col p-5">
                <h1 className="text-[1.4rem] font-bold p-5">Közösségi média</h1>
                <br />
                <div className="flex flex-row text-left justify-evenly">
                  <a href="https://www.facebook.com/hopextrem/">
                    <FontAwesomeIcon icon={faFacebook} size="2xl" />
                  </a>
                  <a href="https://www.instagram.com/ninjapadlas/">
                    <FontAwesomeIcon icon={faInstagram} size="2xl" />
                  </a>
                  <a href="https://ninjapadlas.booked4.us/public/">
                    <FontAwesomeIcon icon={faBook} size="2xl" />
                  </a>
                </div>
              </div>
            </div>
            <br />
            <h1 className="text-[1.8rem] font-bold text-center p-5">
              Szponzoraink
            </h1>
            <div
              onClick={this.hideMobileMenu}
              className="w-screen h-auto flex lg:flex-row flex-col text-center items-center justify-evenly relative"
              id="sportagak"
            >
              <a href="https://hclinear.hu/">
                <center>
                  <img
                    className="lg:w-[35%] sm:w-[40%] w-[45%] m-5"
                    src={hcLinear}
                    alt="HC Linear"
                  />
                </center>
              </a>
              <a href="https://t-rock.hu/">
                <center>
                  <img
                    className="lg:w-[20%] sm:w-[40%] w-[45%] m-5"
                    src={TRock}
                    alt="T-Rock"
                  />
                </center>
              </a>
            </div>
            <h1 className="text-[1.8rem] font-bold text-center p-5">
              © 2024 Ninja Padlás SE
            </h1>
          </footer>
        </div>
      </div>
    );
  }
}

export default HomePage;
