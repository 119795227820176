import React, { Component } from "react";
import { Link } from "react-router-dom";

class NinjaWarriorTortenete extends Component {
  scrollUp = () => {
    window.scrollTo(0, 0);
  };

  render() {
    this.scrollUp();
    return (
      <div>
        <div className="w-auto md:h-14 h-auto flex-row flex md:justify-around justify-start items-center p-3 bg-[#256555]">
          <div className="w-screen hidden md:mr-8 md:flex pl-4">
            <h1 className="w-auto h-14 flex md:text-center items-center text-slate-100 font-bold text-3xl font-bebas indent-8">
              Ninja Padlás
            </h1>
          </div>
          <div className="w-auto md:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
            <div className="flex md:flex-row flex-col">
              <h1 className="cursor-pointer">
                <Link to="/">Vissza</Link>
              </h1>
            </div>
          </div>
        </div>
        <div className="w-screen h-auto md:pr-16 md:pl-16 md:pt-16 p-[20px] pt-12">
          <div>
            <h1 className="text-black text-[2.5rem] font-bold font-bebas">
              A Ninja Warrior története
            </h1>
            <div className="md:w-[350px] w-[250px] h-2 bg-black rounded"></div>
          </div>
          <div className="h-auto mt-16">
            <p className="font-light text-xl text-black">
              A Ninja Warrior mára már egy nemzetközileg ismert sportműsor,
              melyet minden országban egy Tv-csatorna közvetít, így érve el a
              nagyközönséget. Világszerte rendezik meg évente az eseményt.
              Legtöbb évadig Amerikában jutottak el, a műsor már a 15-ik
              évadjában jár. Európában a sorozat Németországban és
              Franciaországban is 7-7 évadnál tart. További országok, ahol még a
              műsor megjelent például: Románia, Vietnám, Ausztria, Magyarország
              (erről később).
              <br />
              <br />
              Az Amerikából kiinduló sportnak alapjául a Japán, Sasuke nevezetű
              TV show szolgált, ami először a Banzuke részeként szerepelt, majd
              nőtte ki magát különálló műsorrá. Ezt vették át az amerikaiak és
              nevezték el „American Ninja Warrior” -nak (ANW). Lényege, hogy a
              résztvevő versenyzőknek több fordulóban (selejtezők, elődöntő,
              döntő) kell hiba nélkül teljesíteniük egy ügyességi akadálypályát.
              A döntőben négy pálya van, hármat szervezők állítanak össze, a
              negyedik azonban mindig a „Mount Midoriyama” , ami egy 20 méter
              hosszú kötél, megmászására 30 másodperc áll rendelkezésre. Az
              akadályok alatt vízzel teli medence van, ha a versenyző hozzáér a
              vízhez kizárják a versenyből. Az akadályok minden téren próbára
              teszik az egyén atletikus képességeit. A hangsúly a felsőtest
              (hát, bicepsz) és az alkarok kifárasztásán van. A különböző
              akadályok, fogások erős szorítóerőt és jó állóképességet
              igényelnek, hogy a versenyző ne pottyanjon be a vízbe. Sokszor
              pedig, hogy elérj egy következő akadályt hajlított kézzel kell
              tartanod magad, ami nem egy egyszerű feladat a hétköznapi ember
              számára. Az erőt próbáló feladatok között pedig ügyességi,
              egyensúlyozó akadályok vannak, melyekhez gyorsaságra és nagy
              precizitásra van szükség.
              <br />
              <br />
              <br />
              Magyarországon a sorozat három évadig jutott, ebben a három évben
              a totális győzelmet nem sikerült senkinek sem elérnie. Ehhez
              tavaly Harmat Krisztián került a legközelebb, de rajta is kifogott
              a nagy kötél. Az eddigi győztesek (Legtovább talpon maradt ninják)
              háttere jól mutatja, hogy mennyire szükség van az atletikus
              képességekre. Az első két évad győztese Gyömrei Máté és Strommer
              Soma mindketten mászók, azaz alkarjuk, ujjaik borzasztóan erősek,
              ez meg is látszott nagyon jól teljesítettek a mászós akadályokon.
              Míg Krisz parkouros, látványos, gyors mozgásával hamar legyűrte az
              akadályokat így több ereje maradt.
              <br />
              <br />
              Összességében egy nagyon jó sport, mert az ember egész testét
              megmozgatja. Az erő mellett ugyanúgy fejleszti a koordinációt és
              az állóképességet is, így aki ezzel foglalkozik nagyon hasznos
              képességekkel gazdagodik.
            </p>
          </div>
          <br />
          <h3 className="w-auto text-[#40806e] text-right text-[1.25rem] font-bold">
            Horgas Péter
          </h3>
        </div>
      </div>
    );
  }
}

export default NinjaWarriorTortenete;
