import React, { Component } from "react";
import { Link } from "react-router-dom";

class Parkour extends Component {
  scrollUp = () => {
    window.scrollTo(0, 0);
  };
  render() {
    this.scrollUp();
    return (
      <div>
        <div className="w-auto md:h-14 h-auto flex-row flex md:justify-around justify-start items-center p-3 bg-[#256555]">
          <div className="w-screen hidden md:mr-8 md:flex pl-4">
            <h1 className="w-auto h-14 flex md:text-center items-center text-slate-100 font-bold text-3xl font-bebas indent-8">
              OCR
            </h1>
          </div>
          <div className="w-auto md:w-auto h-auto mr-8 flex flex-row text-center items-center font-medium text-xl text-slate-100">
            <div className="flex md:flex-row flex-col">
              <h1 className="cursor-pointer">
                <Link to="/">Vissza</Link>
              </h1>
            </div>
          </div>
        </div>
        <div className="w-screen h-auto md:pr-16 md:pl-16 md:pt-16 p-[20px] pt-12">
          <div>
            <h1 className="text-black text-[2.5rem] font-bold font-bebas">
              Az Obstacle Course Racing története
            </h1>
            <div className="md:w-[350px] w-[250px] h-2 bg-black rounded"></div>
          </div>
          <div className="h-auto mt-16">
            <p className="font-light text-xl text-black">
              Az első, mai értelemben vett hivatalos (katonai) OCR versenyt 1947
              augusztusában rendezték meg a francia Henri Debrus kapitány
              kezdeményezésére, aki a hagyományos öttusát szerette volna a
              modern katonai elveknek megfelelően a kiképzéshez alakítani. Akkor
              még ejtőernyős ugrással kezdődött a verseny, majd 20 km
              terepfutás, gyaloglás következett, és közben kúszó, mászó,
              függeszkedő, cipelő és egyensúlyi akadályokat kellett leküzdeni,
              természetesen további katonai gyakorlatokkal kiegészítve
              (gránátdobás, célbalövés). A holland ejtőernyősök kiképzését vette
              alapul, a hollandok azóta is kimagaslóan jók az OCR sportban! 1950
              óta évenkénti világbajnokságon mérik össze erejüket a katonák.
              <br />
              <br />
              <br />
              Az 1980-as 90-es években egyre több versenyt rendeztek, ami lassan
              megalapozta a sport mai népszerűségét, de a nagy áttörést egy
              igazán komoly marketing ötlet hozta meg, hiszen akkoriban az
              emberek többsége még a betonon, az országúton, vagy a teremben
              tudta csak elképzelni a sportolást. 2010-ben elindult a Spartan
              Race versenysorozat, amely nagyságrendekkel megnövelte az OCR
              sport ismertségét. Innentől kezdve már nemcsak a „kevesek” hobbija
              volt ez a sport, hanem pillanatok alatt vágyott cél lett rengeteg
              aktív ember számára.
              <br />
              <br />
              Az <b>OCR</b> (Obstacle Course Racing) persze ennél sokkal több,
              egészen más típusú versenyeket és próbákat is jelent. Az OCR
              sportág nemzetközi szövetsége (FISO – Fédération Internationale de
              Sports d’Obstacles) 2014-ben alakult és jelenleg már több, mint 80
              ország képviselői alkotják a tagságát. 
              <br />
              <br />
              <b>
                A magyar Szövetség (HOCRA – Hungarian OCR Association /
                Terepakadályfutó és Akadálysport Szövetség) 2017-ben
                csatlakozott a nemzetközi szervezethez
              </b>{" "}
              amely működteti a nemzeti bajnokságokat, a kupafutamokat, részt
              vesz a sportág nemzetközi felépítésében..
              <br />
              <br />
              Az OCR versenyekről: az akadályok a versenyek jellegétől függően
              lehetnek természetes és épített akadályok, a versenyszervezők
              maguk is egyre jobban törekszenek az akadályok változatos
              kialakítására, tesztelve a versenyzők nemcsak fizikai, de szellemi
              állóképességét is. A versenyek általában sportstadionokban, erdős
              területeken, városokban és parkokban kerülnek megrendezésre.
              <br />
              Az akadályok a régi harci kiképzésben használt elemeket foglalják
              magukba. Jellemzően olyan gyakorlatok, ahol a versenyző a saját
              testsúlyát kell, hogy mozgassa: pl. magasabb akadályokra való
              felmászás, kötélmászás, vizes akadályok leküzdése, de ide
              sorolhatunk minden természetes mozgást is.
            </p>
          </div>
          <br />
          <h3 className="w-auto text-[#40806e] text-right text-[1.25rem] font-bold">
            Horgas Péter
          </h3>
        </div>
      </div>
    );
  }
}

export default Parkour;
