import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

function Comment({ text, name }) {
  const bgColor = "#041c16";
  const textColor = "#fff";
  const lightGreen = "#89d7be";
  const darkGreen = "#316f5e";
  return (
    <div className="w-full h-auto flex lg:flex-row flex-col justify-evenly items-center text-left p-5 m-5">
      <h1 className="w-full font-bold text-[1.5rem] p-3 rounded-xl">
        <div className="flex">
          <FontAwesomeIcon icon={faStar} size="1x" />
          <FontAwesomeIcon icon={faStar} size="1x" />
          <FontAwesomeIcon icon={faStar} size="1x" />
          <FontAwesomeIcon icon={faStar} size="1x" />
          <FontAwesomeIcon icon={faStar} size="1x" />
        </div>
        {text}
        <br />
        <span className="font-normal text-[1.25rem]">{name}</span>
      </h1>
    </div>
  );
}

export default Comment;
